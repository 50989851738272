var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-card',{staticClass:"q-pa-lg state-stepper",attrs:{"flat":""}},[_c('div',{staticClass:"text-default font-16 w-600 q-mb-md"},[_vm._v(" "+_vm._s(_vm.$t('product.stepper.title'))+" ")]),_c('q-separator'),_vm._l((_vm.STAGES),function(title,step){return _c('div',{key:("np-stage" + step),staticClass:"q-mt-lg"},[_c('div',{staticClass:"row items-center"},[_c('div',{class:{
          'step-circle row justify-center items-center': true,
          done: _vm.currentStage > step,
          active: _vm.currentStage === step,
          disable: _vm.currentStage < step
        }},[(_vm.currentStage > step)?_c('q-icon',{attrs:{"color":"white","name":"fas fa-check"}}):_c('div',{class:{
            'text-white': _vm.currentStage === step,
            'text-np-gray-800': _vm.currentStage < step
          }},[_vm._v(" "+_vm._s(step)+" ")])],1),_c('div',{staticClass:"q-ml-sm"},[_vm._v(" "+_vm._s(_vm.$t(title))+" ")])])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }