






















import { defineComponent } from '@vue/composition-api';
import { WYSIWYGEditor } from '@ligo/shared/components';
import moment from 'moment';

export default defineComponent({
  name: 'BaseChatMessage',
  components: { WYSIWYGEditor },
  props: {
    color: {
      type: String,
      required: true
    },
    sender: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    time: {
      type: String,
      required: true
    },
    isUserMessage: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      moment
    };
  }
});
