




































import { defineComponent, PropType, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'NotaryProductTabs',
  props: {
    tabs: {
      type: Array as PropType<
        {
          label: string;
          key: string;
          hide?: boolean;
          badge?: number;
        }[]
      >,
      required: true
    }
  },
  setup(props, { emit }) {
    const currentTab = ref(props.tabs[0].key);

    function onTabChanged(tabKey: string) {
      currentTab.value = tabKey;
      emit('tab-changed', tabKey);
    }
    return { currentTab, onTabChanged };
  }
});
