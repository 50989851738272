















































































































































































































import {
  defineComponent,
  PropType,
  ref,
  computed,
  watch
} from '@vue/composition-api';
import ReadDocumentsHandler from './ReadDocuments.handler.vue';
import UploadDocumentsHandler from './UploadDocuments.handler.vue';
import BaseSimpleForm from '../../base/SimpleForm/BaseSimpleForm.vue';
import AlertModal from '../../documents/base/AlertModal.vue';
import { APrimaryBtn, ASecondaryBtn } from '../../base/app/buttons';
import { BusyNotary } from './banners';

import { useReadDocuments } from './read-documents.hook';
import {
  DIALOG_WITHOUT_NOTARY_KEY,
  DialogPageInformation,
  useCustomerNotarySelection
} from '../extra-questions/extra-questions.hooks';
import { useSimpleFormConfig } from '../../base/SimpleForm/hooks/simpleForm.hooks';
import { useDynamicText } from '../hooks/dynamicText.hooks';

import { NotaryProductSlug } from '@ligo/bv-flow/store';
import {
  Notary,
  NotaryCapacityStatus,
  NotaryProductEvent
} from '@ligo/dashboard/store';

import { useI18n, useStore } from '../../../composables';

const AVAILABILITY_INFO_KEY = 'availability_status';

export default defineComponent({
  name: 'ReadUploadStep',
  components: {
    ReadDocumentsHandler,
    UploadDocumentsHandler,
    BusyNotary,
    BaseSimpleForm,
    AlertModal,
    APrimaryBtn,
    ASecondaryBtn
  },
  props: {
    notary: {
      type: Object as PropType<Notary>
    },
    productType: {
      type: String as PropType<NotaryProductSlug>
    },
    notaryId: {
      type: Number
    },
    productData: {
      type: Object
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const i18n = useI18n();

    const allDocsUploaded = ref(false);
    const loading = ref(false);
    const showConfirmDialog = ref(false);
    const showTooltip = ref(false);

    emit('continue-loading');

    const notaryProduct = store.getters['notaryProduct/getNotaryProductId'];

    const { nt } = useDynamicText(i18n, props.notary);

    const busyNotaryBanner = ref(nt('read_docs.enable_busy_banner'));

    const {
      items,
      loading: readDocsLoading,
      docsLoaded,
      onToggleRead,
      onDownload
    } = useReadDocuments();

    const {
      formConfig,
      notaryList,
      onSendInfo,
      loadPageInformation,
      t,
      te
    } = useCustomerNotarySelection(
      notaryProduct,
      props.productType,
      props.notaryId,
      () => cantMoveOn.value
    );

    const onAllDocsRead = computed(() =>
      items.value.every((item) => item.status)
    );

    const readDocsElements = computed(() => ({
      loadingDocs: readDocsLoading.value,
      docList: items.value,
      readDoc: onToggleRead,
      downloadDoc: onDownload
    }));

    const cantMoveOn = computed(() => {
      return !onAllDocsRead.value || !allDocsUploaded.value;
    });

    const { formValue, getFormValueReducer } = useSimpleFormConfig(
      formConfig,
      props.productData
    );

    const termsAndConditions = computed(() => {
      const notaryId = formValue.value['notary_id'];
      if (notaryId) {
        const notary = notaryList.value.find(
          (notary) => notary.id === notaryId
        );
        if (notary && notary.terms_and_conditions)
          return notary.terms_and_conditions;
      }
      return false;
    });

    const getAvailabilityInfo = computed(() => {
      return t<{ [key in NotaryCapacityStatus]: string }>(
        AVAILABILITY_INFO_KEY
      );
    });

    const getDialogInfo = computed(() =>
      t<DialogPageInformation>(DIALOG_WITHOUT_NOTARY_KEY)
    );

    function onSend() {
      emit('event', NotaryProductEvent.CHECK_DOCUMENTS);
    }

    function onAllDocsUploaded(val: boolean) {
      allDocsUploaded.value = val;
    }

    function onMoveNext() {
      loading.value = true;
      onSendInfo(getFormValueReducer())
        .then(() => {
          onSend();
        })
        .finally(() => {
          loading.value = false;
        });
    }

    watch(docsLoaded, () => {
      emit('stop-loading');
    });

    loadPageInformation();

    return {
      allDocsUploaded,
      onAllDocsRead,
      busyNotaryBanner,
      termsAndConditions,
      cantMoveOn,
      readDocsElements,
      docsLoaded,
      loading,
      formValue,
      getAvailabilityInfo,
      formConfig,
      showTooltip,
      getDialogInfo,
      showConfirmDialog,
      onAllDocsUploaded,
      onSend,
      t,
      te,
      nt,
      onMoveNext
    };
  }
});
