













































































































































































import {
  defineComponent,
  PropType,
  ref,
  Ref,
  computed
} from '@vue/composition-api';
import {
  Notary,
  NotaryProductDocument,
  NotaryProductTypeSlug
} from '@ligo/dashboard/store';
import { ReadonlyAmendmentDialog } from '@ligo/dashboard/components';

import UploadDialog from '../../global/UploadDialog.vue';
import InfoBanner from '../../global/InfoBanner.vue';
import ItemViewMessage from './ItemViewMessage.vue';
import ItemPending from './ItemPending.vue';
import ItemAccepted from './ItemAccepted.vue';
import ItemTemplate from './ItemTemplate.vue';

import { useCheckDocuments } from './check-documents.hook';
import { onDownload } from '../documents.hooks';
import { useDynamicText } from '../hooks/dynamicText.hooks';
import { getLastUpdatedDocument } from '../documents.hooks';
import { CtaBanner } from '../../base/banners';
import { useI18n, useStore } from '../../../composables';

export default defineComponent({
  name: 'CheckDocuments',
  components: {
    InfoBanner,
    ReadonlyAmendmentDialog,
    UploadDialog,
    ItemViewMessage,
    ItemPending,
    ItemAccepted,
    ItemTemplate,
    CtaBanner
  },
  props: {
    productType: {
      type: String as PropType<NotaryProductTypeSlug>
    },
    notary: {
      type: Object as PropType<Notary>
    }
  },
  setup(props, { emit }) {
    emit('stop-loading');

    const store = useStore();

    const {
      requestedDocs,
      rejectedDocs,
      acceptedDocs,
      uncheckedDocs,
      loading,
      existsDocs,
      notaryProductId,
      items,
      templateDocs,
      load
    } = useCheckDocuments();

    const showMessage = ref(false);
    const messageText = ref('');
    const onViewMessage = (item: NotaryProductDocument) => {
      messageText.value = item.amendment_request;
      showMessage.value = true;
    };

    const showUpload = ref(false);
    const uploadDocument: Ref<NotaryProductDocument> = ref();
    const onShowUpload = (doc: NotaryProductDocument) => {
      uploadDocument.value = doc;
      showUpload.value = true;
    };

    const i18n = useI18n();
    const { nt } = useDynamicText(i18n, props.notary);

    const lastUpdatedUncheck = computed(() => {
      return getLastUpdatedDocument(uncheckedDocs.value);
    });

    const lastUpdatedTemplates = computed(() => {
      return getLastUpdatedDocument(templateDocs.value);
    });

    const hasBankingRequest = computed(() => {
      return store.getters['notaryProduct/getNotaryProduct']?.banking_request
        ?.uuid;
    });

    return {
      requestedDocs,
      rejectedDocs,
      loading,
      existsDocs,
      showMessage,
      messageText,
      showUpload,
      lastUpdatedUncheck,
      lastUpdatedTemplates,
      notaryProductId,
      uploadDocument,
      acceptedDocs,
      uncheckedDocs,
      items,
      templateDocs,
      hasBankingRequest,
      nt,
      onViewMessage,
      onShowUpload,
      onDownload,
      load
    };
  }
});
