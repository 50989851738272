














import { defineComponent, inject } from '@vue/composition-api';
import { BrandedChangePassword } from '@ligo/shared/auth';
import { brandInformation, brandRouteName } from './auth';
import { RESOURCES } from '@ligo/dashboard/customer/store';

export default defineComponent({
  name: 'ChangePassword',
  components: { BrandedChangePassword },
  props: {
    access_token: {
      type: String
    },
    client: {
      type: String
    },
    expiry: {
      type: String
    },
    uid: {
      type: String
    }
  },
  setup() {
    const brand: string = inject('brand', 'ligo');
    const brandData = brandInformation(brand);
    const base = RESOURCES.AUTH;

    return {
      brand,
      brandData,
      brandRouteName,
      base
    };
  }
});
