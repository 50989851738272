
























































import {
  Notary,
  NotaryProductTypeSlug,
  NotaryProductDocumentKind,
  NotaryProductDocumentType
} from '@ligo/dashboard/store';
import { defineComponent, PropType, computed } from '@vue/composition-api';
import ExpansionCard from '../../global/ExpansionCard.vue';
import ContactInfoCard from './ContactInfoCard.vue';
import DocumentsFolder from '../DocumentsFolder.vue';
import ReadAndPrint from './ReadAndPrint.vue';
import { useStore } from '../../../composables';
import { extractMeetingStepCopies } from './extract-copies-hook';
import { getLastUpdatedDocument, getDocumentsBy } from '../documents.hooks';

const document_types = [
  NotaryProductDocumentType.REGULAR,
  NotaryProductDocumentType.DRAFT_DEED
];
const document_kinds = [
  NotaryProductDocumentKind.USER_UPLOADED,
  NotaryProductDocumentKind.NOTARY_UPLOADED
];

export default defineComponent({
  name: 'ScheduleMeeting',
  components: { ExpansionCard, ContactInfoCard, DocumentsFolder, ReadAndPrint },
  props: {
    productType: {
      type: String as PropType<NotaryProductTypeSlug>
    },
    notary: {
      type: Object as PropType<Notary>
    },
    loading: {
      type: Boolean,
      default: false
    },
    notaryProductId: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    emit('stop-loading');

    const store = useStore();

    const { bannerTitle, documentBannerTitle } = extractMeetingStepCopies(
      store.getters['notaryProduct/getNotaryProduct']
    );

    const notaryProductId = computed(() => props.notaryProductId);

    const {
      items: readAndPrintDocuments,
      load: readAndPrintDocumentsLoad
    } = getDocumentsBy(notaryProductId.value, {
      document_types: [
        NotaryProductDocumentType.POA,
        NotaryProductDocumentType.PDC
      ]
    });

    const lastUpdatedReadAndPrintDocuments = computed(() => {
      if (
        !readAndPrintDocuments.value ||
        readAndPrintDocuments.value.length === 0
      ) {
        return '';
      }
      return getLastUpdatedDocument(readAndPrintDocuments.value);
    });

    const {
      items: meetingDocuments,
      load: meetingDocumentsLoad
    } = getDocumentsBy(notaryProductId.value, {
      kinds: document_kinds ?? [],
      document_types: document_types ?? []
    });

    const lastUpdatedMeetingDocuments = computed(() => {
      if (!meetingDocuments.value || meetingDocuments.value.length === 0) {
        return '';
      }
      return getLastUpdatedDocument(meetingDocuments.value);
    });

    const {
      items: templateDocuments,
      load: templateDocumentsLoad
    } = getDocumentsBy(notaryProductId.value, {
      kinds: [
        NotaryProductDocumentKind.LIGO_UPLOADED,
        NotaryProductDocumentKind.FIXED,
        NotaryProductDocumentKind.GENERATED
      ],
      document_types: []
    });

    const lastUpdatedTemplateDocuments = computed(() => {
      if (!templateDocuments.value || templateDocuments.value.length === 0) {
        return '';
      }
      return getLastUpdatedDocument(templateDocuments.value);
    });

    return {
      document_types,
      document_kinds,
      bannerTitle,
      documentBannerTitle,
      NotaryProductDocumentKind,
      templateDocuments,
      meetingDocuments,
      lastUpdatedMeetingDocuments,
      lastUpdatedTemplateDocuments,
      readAndPrintDocuments,
      lastUpdatedReadAndPrintDocuments,
      templateDocumentsLoad,
      meetingDocumentsLoad,
      readAndPrintDocumentsLoad
    };
  }
});
