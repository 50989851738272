











import { defineComponent, watch } from '@vue/composition-api';
import SigningDocument from '../../../components/documents/signing-document/SigningDocument.handler.vue';
import { simpleNotification, NotificationType } from '@ligo/shared/utils';
import { useI18n } from '../../../composables';

export default defineComponent({
  name: 'SigningDocumentPage',
  components: { SigningDocument },
  props: {
    contractUuid: {
      type: String,
      required: true
    },
    signerUuid: {
      type: String,
      required: true
    },
    sessionUuid: {
      type: String,
      required: true
    }
  },
  setup(_, { root }) {
    let clearNotification: () => void;
    const i18n = useI18n();
    watch(
      () => root.$q.screen.lt.sm,
      (isMobile) => {
        if (isMobile) {
          clearNotification = simpleNotification(
            i18n.t('e_signature.not_ready_feature.mobile'),
            NotificationType.warning
          ) as () => void;
        } else {
          clearNotification();
        }
      },
      { immediate: true }
    );

    return {};
  }
});
