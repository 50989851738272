





























import { defineComponent } from '@vue/composition-api';
import ASecondaryBtn from '../app/buttons/ASecondaryBtn.vue';
import { RawLocation } from 'vue-router';

export default defineComponent({
  name: 'CtaBanner',
  components: { ASecondaryBtn },
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    cta: {
      type: String
    },
    to: {
      type: Object as () => RawLocation
    }
  },
  setup() {
    return {};
  }
});
