export default {
  step: 'Stap van de vragenlijst',
  on_delete_dialog: {
    title: 'Weet je zeker dat je deze dienst wilt verwijderen?',
    subtitle:
      'Deze actie kan niet ongedaan worden gemaakt en alle wijzigingen zullen verloren gaan.',
    delete_cta: 'Verwijderen'
  },
  on_delete:
    'Weet je zeker dat je deze dienst wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt en alle wijzigingen zullen verloren gaan.',
  add_new_service: 'Dienst toevoegen',
  payment: 'Betaling',
  finished: 'Afgerond',
  stepper: {
    title: 'Processtatus'
  },
  state: {
    extra_questions: 'Overige vragen',
    read_upload: 'Lees en upload je documenten',
    check_doc: 'Controleer je documenten',
    deed: 'Controleer je FCA',
    meeting: 'Afspraak met notaris',
    finished: 'Afgerond'
  },
  in_progress: 'In behandeling',
  to_do: 'Te doen',
  completed: 'Klaar',

  no_data: 'Er zijn geen gegevens om te laten zien',
  document_file: 'Document bestand',
  document_file_required: 'Documentbestand is required',
  sent_success: 'De documenten zijn succesvol verzonden naar de notaris',
  sent_error: 'Er is een fout opgetreden bij het verzenden van de documenten'
};
