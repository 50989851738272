import { computed } from '@vue/composition-api';
import {
  NotaryProductDocumentAcceptance,
  NotaryProductDocumentKind
} from '@ligo/dashboard/store';
import { getDocumentsBy } from '../documents.hooks';
import { useStore } from '../../../composables';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useCheckDocuments() {
  const store = useStore();
  const notaryProductId = store.getters['notaryProduct/getNotaryProductId'];

  const templateDocs = getDocumentsBy(notaryProductId, {
    kinds: [
      NotaryProductDocumentKind.FIXED,
      NotaryProductDocumentKind.GENERATED,
      NotaryProductDocumentKind.LIGO_UPLOADED
    ]
  });

  const { items, loading, load } = getDocumentsBy(notaryProductId, {
    kinds: [
      NotaryProductDocumentKind.USER_UPLOADED,
      NotaryProductDocumentKind.NO_UPLOADED
    ],
    per_page: 25
  });

  const rejectedDocs = computed(() =>
    items.value?.filter(
      (doc) => doc.acceptance == NotaryProductDocumentAcceptance.REJECTED
    )
  );
  const requestedDocs = computed(() =>
    items.value?.filter(
      (doc) => doc.kind == NotaryProductDocumentKind.NO_UPLOADED
    )
  );

  const uncheckedDocs = computed(() =>
    items.value?.filter(
      (doc) =>
        doc.acceptance == NotaryProductDocumentAcceptance.UNCHECKED &&
        doc.kind == NotaryProductDocumentKind.USER_UPLOADED
    )
  );

  const acceptedDocs = computed(() =>
    items.value?.filter(
      (doc) => doc.acceptance == NotaryProductDocumentAcceptance.ACCEPTED
    )
  );

  const existsDocs = computed(
    () => rejectedDocs.value?.length > 0 || requestedDocs.value?.length > 0
  );

  return {
    rejectedDocs,
    requestedDocs,
    loading,
    existsDocs,
    notaryProductId,
    uncheckedDocs,
    acceptedDocs,
    templateDocs: templateDocs.items,
    items,
    load,
  };
}
