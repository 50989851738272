import nav from './nav';
import page from './page';
import account from './account';
import read_docs from './product-steps/read_docs';
import home from './home';
import product from './product';
import contract from './contract';
import ask_question from './ask_question';
import check_docs from './product-steps/check_docs';
import deed from './product-steps/deed';
import finalized from './product-steps/finalized';
import documents from './documents';
import meeting from './product-steps/meeting';
import quotes from './quotes';
import billing from './billing';
import payment from './payment';
import partners from './partners';
import extra_questions from './product-steps/extra_questions';
import e_signature from './e_signature';
import validations from './validations';
import checkup from './checkup';
import search from './search';
import s_q_table from './s_q_table';
import service_types from './service_types';
import dga from './dga';
import tax from './tax';
import lawyers from './lawyers';
import chat from './chat';

export const nl_customer_dashboard = {
  your_workspaces: 'Jouw workspaces',
  download: 'Download',
  view_all: 'Bekijk alles',
  seconds_ago: 'seconds ago',
  minutes_ago: 'minutes ago',
  hours_ago: 'hours ago',
  days_ago: 'days ago',
  months_ago: 'months ago',
  years_ago: 'years ago',
  cancel: 'Cancel',
  next: 'Next',
  confirm: 'Confirm',
  all: 'All',
  nav,
  page,
  account,
  home,
  product,
  ask_question,
  read_docs,
  deed,
  contract,
  finalized,
  check_docs,
  documents,
  meeting,
  quotes,
  billing,
  payment,
  partners,
  extra_questions,
  e_signature,
  validations,
  checkup,
  search,
  s_q_table,
  service_types,
  dga,
  tax,
  lawyers,
  chat
};
