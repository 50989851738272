import { ApiService } from '@ligo/shared/utils';
import { BaseChatMessage } from '../models/base-chat';
import { CHAT_RESOURCES } from '../resources';

export const NotaryProductChatService = {
  getChat: function (notaryProductId: string) {
    return ApiService.get<{ uuid: string; messages: BaseChatMessage[] }>(
      CHAT_RESOURCES.CREATE_CHAT(notaryProductId)
    );
  },
  sendMessage: function (chatUuid: string, content: string) {
    return ApiService.post(CHAT_RESOURCES.CREATE_MESSAGE(chatUuid), {
      content
    });
  },
  markAsRead: function (chatUuid: string, messageUuid: string) {
    return ApiService.patch(
      CHAT_RESOURCES.CHECK_MESSAGE(chatUuid, messageUuid),
      {}
    );
  }
};
