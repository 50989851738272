export default {
  home_page: {
    invalid_file:
      'Ongeldig document. Het moet een van de volgende formaten zijn: .doc, .docx of .pdf.'
  },
  global: {
    drawer: {
      actions: {
        continue: 'Verstuur voor ondertekening',
        back: 'Terug',
        decline: 'Afwijzen'
      }
    }
  },
  ctas: {
    save: 'Opslaan',
    cancel: 'Annuleer',
    remove: 'Verwijder'
  },
  base_input: {
    placeholder: 'Typ hier...'
  },
  add_signers: {
    board: {
      title: '',
      subtitle: 'Voeg ondertekenaars toe',
      actions: {
        continue: 'Doorgaan',
        cancel: 'Annuleer'
      },
      CRUD: {
        add:
          'Voeg toe | Voeg een ondertekenaar toe | Voeg nog een ondertekenaar toe | Voeg nog een ondertekenaar toe',
        add_yourself: 'Voeg jezelf toe',
        input: {
          placeholder: 'Voer een e-mailadres'
        }
      }
    }
  },
  errors: {
    expired: {
      title: 'De link voor ondertekening is verlopen',
      text: 'Neem contact op met de verzender om een nieuwe link te ontvangen'
    },
    completed: {
      title: 'Je ondertekening is afgerond',
      text: 'Je hebt dit document al ondertekend'
    },
    sent_for_signing: {
      title: 'Dit document is verzonden voor ondertekening',
      text:
        'Dit document is al voorbereid en verzonden voor ondertekening. Je kunt het document niet meer aanpassen'
    },
    declined: {
      title:
        'Dit document is afgewezen door een ondertekenaar en is niet meer geldig',
      text:
        'Neem contact op met de persoon die je heeft uitgenodigd voor meer informatie'
    },
    back_to_home: 'Terug naar home'
  },
  prepare_document: {
    board: {
      title: 'Ondertekenaars toevoegen',
      subtitle: 'Voeg velden toe en onderteken',
      click_field_hint: 'Click to scroll to field',
      signers: {
        add: 'Ondertekenaar toevoegen',
        mail_placeholder: 'Voeg email toe'
      },
      no_fields: {
        paragraph_1:
          'Klik ergens in het document om ondertekenaars en velden toe te voegen.',
        paragraph_2:
          'TDit zijn de velden waar alle ondertekenaars hun gegevens zullen invullen, wanneer je hen het document voor ondertekening stuurt.',
        message:
          'Je moet voor iedere ondertekenaar minstens één handtekening veld toevoegen.'
      },
      fields: {
        description: `Velden die je hebt toegevoegd`,
        edit_signer: 'Bewerk ondertekenaars',
        types: {
          signature: 'Handtekening',
          date: 'Datum',
          text: 'Tekst',
          checkbox: 'Selectievakje'
        },
        add_myself: 'Voeg mezelf toe'
      },
      actions: {
        continue: 'Doorgaan',
        cancel: 'Annuleer',
        add: 'Add',
        edition: {
          save: 'Opslaan',
          cancel: 'Annuleer',
          delete: 'Remove signer',
          remove: 'Remove',
          valid_email: 'Email is not valid'
        }
      }
    },
    page: {
      tooltip: 'Klik om een veld toe te voegen'
    },
    modals: {
      delete: {
        title: 'Are you sure?',
        subtitle:
          'Are you sure you want to remove the signer and all of their associated fields?'
      },
      send_mails: {
        title: 'Controleer en verstuur voor ondertekening',
        signers: 'Ondertekenaars',
        expiration: {
          title: 'Vervaldatum',
          interval:
            'Het verzoek om ondertekening vervalt over {amountDays} dagen, op {finalDate}',
          message:
            'Wanneer de vervaldatum nadert, worden aan alle ondertekenaars meerdere herinneringen gestuurd om ervoor te zorgen dat iedereen op tijd ondertekent.'
        },
        message: 'Persoonlijke boodschap (optioneel)',
        cta: 'Verstuur',
        copy_senders: {
          title:
            'Wie dient nog meer het getekende exemplaar te ontvangen? (optioneel)',
          placeholder: 'Type an email address...',
          extra_info:
            'Deze personen tekenen het document niet, maar ontvangen het getekende document en de signing log.',
          mail_rule: 'Please enter a valid email address',
          add: 'Voeg toe',
          description:
            'Deze personen tekenen het document niet, maar ontvangen het getekende document en de signing log.'
        }
      },
      success: {
        signer: {
          title:
            'Verstuurd voor ondertekening. Nu is het jouw beurt om te tekenen.',
          description:
            'Alle ondertekenaars ontvangen een e-mailbijlage van het voltooide document zodra iedereen heeft ondertekend.'
        },
        no_signer: {
          title: 'Verstuurd voor ondertekening',
          description:
            'Alle ondertekenaars ontvangen een e-mailbijlage van het voltooide document zodra iedereen heeft ondertekend.'
        },
        cta: 'Onderteken het document'
      },
      edit: {
        title: 'Bewerk ondertekenaars'
      }
    },
    menu: {
      title: 'Nieuw veld',
      signers_subtitle: 'Toegewezen aan',
      fields_subtitle: 'Soort veld',
      field_types: {
        signature: 'Handtekening',
        date: 'Datum',
        text: 'Tekst',
        checkbox: 'Selectievakje'
      },
      required: 'Verplicht',
      add_cta: 'Toevoegen'
    },
    edit_menu: {
      assigned: ' Toegewezen aan',
      actions: {
        title: 'Acties',
        copy: 'Kopieer',
        delete: 'Verwijder'
      }
    },
    fields: {
      samples: {
        signature: 'Handtekening',
        text: 'Tekst ...',
        date: 'Datum ...'
      }
    }
  },
  sign_document: {
    toolbar: {
      one_remaining: 'field left',
      many_remaining: 'fields left',
      next: 'Next',
      submit: 'Submit',
      decline: 'Decline'
    },
    board: {
      title: '',
      subtitle: 'Onderteken het document',
      required_fields: 'Vereiste velden',
      optional_fields: 'Optionele velden',
      fields: {
        types: {
          signature: 'Handtekening',
          date: 'Datum',
          text: 'Tekst',
          checkbox: 'Selectievakje'
        }
      },
      actions: {
        continue: 'Klaar met ondertekenen',
        cancel: 'Annuleer'
      },
      success: {
        title: 'You successfuly signed this document',
        text:
          'You’ll receive a copy of the signed document at {signerEmail} once everybody signs it. You can close this window for now.'
      },
      decline: {
        title: 'You have declined this document',
        text: 'You can’t sign this document anymore because you declined it.'
      }
    },
    pad: {
      title: 'Voeg je handtekening toe',
      description: 'Teken het',
      draw_new_label: 'Wil je een andere handtekening gebruiken ?',
      draw_new_action: 'Teken een nieuwe',
      remove_title: 'Weet je het zeker?',
      remove_message:
        'Weet je zeker dat je deze handtekening wilt verwijderen?',
      tabs: {
        new: 'Teken',
        used: 'Opgeslagen'
      },
      cta: {
        clear: 'Duidelijk',
        save: 'Opslaan',
        remove: 'Verwijder'
      }
    },
    modals: {
      confirmation: {
        title: 'Klaar om in te dienen?',
        text:
          'We sturen je een kopie van het ondertekende document op {signerEmail} zodra iedereen het heeft ondertekend.',
        ok: 'Indienen',
        cancel: 'Annuleer'
      },
      success: {
        title: 'U heeft dit document met succes ondertekend',
        text:
          'U ontvangt een kopie van het ondertekende document op {signerEmail} zodra iedereen het heeft ondertekend. U kunt dit venster voorlopig sluiten.'
      },
      success_without_account: {
        title: 'Je hebt het document succesvol ondertekend',
        subtitle:
          'Je ontvangt een getekend exemplaar via {signerEmail}, zodra iedereen heeft getekend.',
        create_account:
          'Meer contracten maken en tekenen? Maak vandaag je GRATIS Ligo account aan.',
        features: {
          store_all_documents: 'Automatisch documenten veilig opslaan',
          access_library: 'Toegang tot 100+ template contracten',
          electronic_signature: 'Onderteken documenten digitaal',
          digital_legally: 'Geautomatiseerde juridische assistent'
        },
        cta: 'Maak account aan'
      },
      checkbox: {
        title: 'Vink dit vakje aan?',
        yes: 'Ja',
        no: 'Nee'
      },
      decline: {
        title: 'Weet je het zeker?',
        text: 'Bevestig dat je dit document wilt weigeren.',
        decline: 'Afwijzen',
        cancel: 'Annuleer'
      },
      fill_text: {
        title: 'Voeg tekst toe'
      },
      fill_date: {
        title: 'Datum toevoegen'
      }
    },
    fields: {
      samples: {
        signature: 'Handtekening',
        text: 'Tekst ...',
        date: 'Datum ...'
      }
    }
  },
  not_ready_feature: {
    mobile:
      'Het spijt ons, maar de documentvoorbereiding is momenteel niet beschikbaar voor mobiele apparaten.'
  }
};
