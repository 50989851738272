export default {
  Home: 'Dashboard',
  Account: 'Account',
  Settings: 'My account settings',
  AddMember: 'Add members',
  DirectorsShareholders: 'Directors & shareholders',
  Discounts: 'Discounts',
  NotaryProductDetail: 'My Services',
  Incorporation: 'Incorporation',
  NotarialDeeds: 'Notarial deeds',
  OtherServices: 'Other services',
  MyDocuments: 'My documents',
  CreateDocument: 'Contract library',
  CreateTemplate: 'Create template',
  ListQuotes: 'Quote Requests',
  RequestQuote: 'Quote Requests',
  QuotesDetails: 'Quote details',
  NotaryProducts: 'My Services',
  ActiveContracts: 'My Documents',
  Companies: 'My Companies',
  NewQuote: 'New Quote',
  Billing: 'Billing information',
  Checkout: 'Order Overview',
  AdyenPayment: 'Payment',
  ContractPreview: 'My Documents',
  notary_products: {
    new: 'New service',
    title: 'Services',
    tabs: {
      documents: 'Documents',
      chat: 'Chat with Notary'
    },
    table: {
      columns: {
        name: 'Name',
        status: 'Status',
        type: 'Type',
        last_modified: 'Last modified'
      },
      filters: {
        search: 'Search by name',
        status: 'Status',
        types: {
          label: 'Types',
          labelSelected: ''
        },
        last_modified: 'Last modified'
      }
    }
  },
  contracts: {
    title: 'Create a legal document',
    subtitle: 'Sign and store your legal documents digitally'
  },
  discount: {
    title: 'Discounts',
    subtitle:
      'Get access to special benefits and unique discounts from our partners'
  },
  templates: {
    title: 'Contract Library'
  },
  contract_reminders: {
    title: 'Tasks',
    subtitle:
      'Your legal assistant who automatically reminds you of important tax and legal obligations at the right time.',
    empty_banner: {
      title: 'This is where you manage your legal tasks',
      subtitle:
        'Automate legal tasks and be reminded at the right time to take action, so you stay compliant and can focus on building your business',
      btn: 'New tasks'
    },
    editor_placeholder: 'Write here a message'
  },
  user: {
    title: 'Settings'
  }
};
