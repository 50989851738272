































import { defineComponent, PropType } from '@vue/composition-api';
import { useI18n } from '../../../composables';
import { PaymentView, lineItems } from '../../../models';

export default defineComponent({
  name: 'PaymentSummary',
  props: {
    data: {
      type: Object as PropType<PaymentView>
    }
  },
  setup() {
    const i18n = useI18n();

    function round(value: number) {
      return Number(value).toFixed(2);
    }

    function getItemName(item: lineItems) {
      const key = `payment.line_items.${item.name_nl}`;
      const locale = i18n.locale;
      const name = locale === 'en' ? item.name_en : item.name_nl;
      return i18n.te(key) ? i18n.t(key) : name;
    }

    return { round, getItemName };
  }
});
