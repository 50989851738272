import { ref, computed } from '@vue/composition-api';
import { CHAT_RESOURCES } from '@ligo/dashboard/customer/store';
import { ApiService } from '@ligo/shared/utils';
import { BaseChatMessage, Chat } from '@ligo/dashboard/customer/store';
import { initWebSocket, getBaseSocketURL } from '@ligo/shared/utils';
import { AxiosPromise } from 'axios';
import { NotaryProductChatService } from '@ligo/dashboard/customer/store';

export function useNotaryProductChat(notaryProductId: string) {
  const chatUuid = ref('');

  const chatMessages = ref<BaseChatMessage[]>(undefined);

  const loadingChat = ref(true);

  const sendingMessage = ref(false);

  const unreadMessages = computed(() => {
    return chatMessages.value?.filter(notReadMessage);
  });

  function notReadMessage(message: BaseChatMessage) {
    return !message.seen_at && message.author.type !== 'Member';
  }

  function setupWebSocket(uuid: string) {
    const { url, channelIdentifier } = getBaseSocketURL(
      uuid,
      'ChatCenter::ChatsChannel'
    );

    initWebSocket<Chat, AxiosPromise<Chat>>(
      () =>
        ApiService.get<{ uuid: string; messages: BaseChatMessage[] }>(
          CHAT_RESOURCES.CREATE_CHAT(notaryProductId)
        ),
      undefined,
      url,
      channelIdentifier,
      (state, response) => {
        chatMessages.value = response.data.messages;
      },
      false
    );
  }

  async function loadChat() {
    NotaryProductChatService.getChat(notaryProductId)
      .then((response) => {
        chatUuid.value = response.data.uuid;
        chatMessages.value = response.data.messages;
      })
      .catch((e) => {
        console.log(`there was an error loading chat: ${e}`);
      })
      .finally(() => {
        setupWebSocket(chatUuid.value);
        loadingChat.value = false;
      });
  }

  function onSendMessage(content: string) {
    sendingMessage.value = true;
    NotaryProductChatService.sendMessage(chatUuid.value, content)
      .catch((e) => {
        console.error(`there was an error sending message: ${e}`);
      })
      .finally(async () => {
        sendingMessage.value = false;
      });
  }

  function onMessageVisible(message: BaseChatMessage) {
    if (!message.seen_at && message.author.type !== 'Member') {
      NotaryProductChatService.markAsRead(chatUuid.value, message.uuid).catch(
        (e) => {
          console.error(`there was an error checking message: ${e}`);
        }
      );
    }
  }

  return {
    loadingChat,
    chatMessages,
    sendingMessage,
    unreadMessages,
    notReadMessage,
    onMessageVisible,
    loadChat,
    onSendMessage
  };
}
