















import {
  computed,
  ComputedRef,
  defineComponent,
  inject
} from '@vue/composition-api';
import {
  brandSocialBtn,
  socialAuth,
  providerLinks,
  brandRouteName,
  brandInformation
} from './auth';
import { BrandedLogin } from '@ligo/shared/auth';
import { AuthError } from '@ligo/bv-flow/store';
import { useInit } from '@ligo/dashboard/customer/components';
import { analyticLoginEvent } from '../../analytics';
import {
  ExtraServiceService,
  ContractService,
  MembershipService
} from '@ligo/dashboard/customer/store';

export default defineComponent({
  name: 'Login',
  components: { BrandedLogin },
  props: {
    redirectUrl: String,
    contractUuid: String,
    extraServiceTypeSlug: String,
    planId: String
  },
  setup(props, { root }) {
    const loginError: ComputedRef<AuthError> = computed(() => {
      return root.$store.state.authentication.authenticationError;
    });
    const { init } = useInit(root);

    if (props.contractUuid) {
      ContractService.saveSelection(props.contractUuid);
    }

    if (root.$store.state.authentication.accessToken) {
      if (props.extraServiceTypeSlug) {
        ExtraServiceService.create(props.extraServiceTypeSlug, {
          name: 'Home'
        });
      }
      if (props.contractUuid) {
        ContractService.claimContract(props.contractUuid);
      }
      if (props.planId) {
        MembershipService.createAndRedirect(props.planId);
      }
    } else {
      if (props.extraServiceTypeSlug) {
        ExtraServiceService.saveSelection(props.extraServiceTypeSlug);
      }
      if (props.planId) {
        MembershipService.saveSelection(props.planId);
      }
    }

    function onLogin(username, pass) {
      root.$store
        .dispatch('authentication/auth', {
          data: {
            email: username.value,
            password: pass.value,
            extraServiceTypeSlug: props.extraServiceTypeSlug
          }
        })
        .then(async (result) => {
          if (result) {
            analyticLoginEvent(result.userUuid, result.email);
            init();
            const redirected =
              (await ContractService.checkAndClaimFromStorage()) ||
              (await MembershipService.checkAndCreateFromStorage());
            await ExtraServiceService.checkAndCreateFromStorage();
            if (!redirected) redirect();
          }
        });
    }

    const brand: string = inject('brand', 'ligo');
    const brandData = brandInformation(brand);

    function goToReset() {
      root.$router.push({ name: brandRouteName(brand, 'ResetPassword') });
    }

    function redirect() {
      if (props.redirectUrl) {
        root.$router.push(props.redirectUrl);
      } else {
        root.$router.push({ name: 'Home' });
      }
    }

    function goRegister() {
      const route = { name: brandRouteName(brand, 'Register') };
      route['query'] = {
        redirect_url: props.redirectUrl,
        contract_uuid: props.contractUuid,
        extraServiceTypeSlug: props.extraServiceTypeSlug,
        plan_id: props.planId
      };
      root.$router.push(route);
    }

    const socialBtns = brandSocialBtn(brand);

    return {
      brand,
      onLogin,
      goToReset,
      socialBtns,
      providerLinks,
      loginError,
      socialAuth,
      goRegister,
      brandData
    };
  }
});
