import { computed, ref } from '@vue/composition-api';
import { ApiService, RootContext } from '@ligo/shared/utils';
import { RESOURCES } from '@ligo/dashboard/customer/store';
import { NotaryProductDocumentType } from '@ligo/dashboard/store';
import { getDocumentsBy, onDownload } from '../documents.hooks';
import { useStore } from '../../../composables';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useDeedDocument(root: RootContext, emit: any) {
  const store = useStore();
  const notaryProductId = store.getters['notaryProduct/getNotaryProductId'];

  const cancellingAmendment = ref(false);

  const { items, loading, load } = getDocumentsBy(notaryProductId, {
    document_types: [NotaryProductDocumentType.DRAFT_DEED]
  });

  const existsDeed = computed(() => {
    return items.value.length > 0;
  });

  const onAmmendRequest = async (uuid: string, ammend: string) => {
    try {
      await ApiService.patch(
        RESOURCES.NOTARY_PRODUCT_DOCUMENT_REJECT(notaryProductId, uuid),
        { rejection_reason: ammend }
      );
      load();
    } catch (error) {
      console.log('The following error has occurred: ', error);
    }
  };

  const onCancelAmendment = (uuid: string) => {
    cancellingAmendment.value = true;
    ApiService.patch(
      RESOURCES.CANCEL_AMENDMENT_REQUEST(notaryProductId, uuid),
      {}
    )
      .then(() => {
        load();
      })
      .catch((error) => {
        console.log('The following error has occurred: ', error);
      })
      .finally(() => {
        cancellingAmendment.value = false;
      });
  };

  const onApproveDeed = async (uuid: string): Promise<void> => {
    try {
      await ApiService.patch(
        RESOURCES.NOTARY_PRODUCT_DOCUMENT_ACCEPT(notaryProductId, uuid),
        {}
      );

      load();
    } catch (error) {
      console.log('The following error has occurred: ', error);
    }
  };

  const onGlobalApproval = async (uuid: string): Promise<void> => {
    try {
      await ApiService.patch(RESOURCES.NOTARY_PRODUCT + uuid, {
        event: 'schedule_meeting'
      });

      emit('refresh');
    } catch (error) {
      console.log('The following error has occurred: ', error);
    }
  };

  return {
    items,
    existsDeed,
    loading,
    cancellingAmendment,
    onAmmendRequest,
    onApproveDeed,
    onDownload,
    onGlobalApproval,
    onCancelAmendment
  };
}
