export default {
  title: 'The notary is checking your documents',
  info_banner:
    'Please note that the notary can request additional documents, such as a business plan, or request amendments to the submitted documents if needed.  This is standard procedure. Once all documents are approved, the notary will prepare the final deed and you will be automatically directed to the next step. You will receive a notification.',
  request_header: 'Further information requested by notary',
  reject_header: 'Rejected documents',
  accepted_header: 'Approved by the notary',
  view: 'View message',
  upload: 'Upload',
  uploaded: 'Uploaded',
  change: 'Change',
  send: 'Send documents to the notary',
  modal: {
    title: 'Document requested by the notary',
    view: {
      title: 'Reason for requesting',
      subtitle: 'Description of what you need to do'
    },
    ok: 'Ok'
  },
  pending: 'Pending approval',
  accepted: 'Accepted',
  documents: 'Documents you uploaded',
  templates: 'Ligo forms and templates',
  documents_approved: '{used}/{total} documents approved',
  edit_application: 'Edit application',
  menu: {
    company_name: 'Change company name',
    company_address: 'Change company address',
    update_shareholder: 'Update shareholders’ details',
    remove_shareholders: 'Add/remove shareholders',
    business_activities: 'Update business activity',
    share_capital: 'Modify share capital',
    other: 'Other changes',
    cancel: 'Cancel application'
  },
  banking_request_cta: 'Request my account',
  banking_request_banner: 'You can now request a banking account via Ligo. You will get a Dutch IBAN within 3 hours of registration at the Chamber of Commerce!'
};
