var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-banner',{class:"full-width row items-center q-px-md q-py-md bg-np-secondary-500"},[_c('div',{staticClass:"full-width row items-center font-12 w-400 text-dark-blue"},[_c('q-icon',{attrs:{"name":_vm.icon,"size":"18px","color":"subdued"}}),_c('div',{class:{
        'col row justify-between items-center': true,
        'q-pl-md q-pr-lg': !_vm.$q.screen.lt.md,
        'col-12 q-mt-sm': _vm.$q.screen.lt.md
      }},[_c('div',{class:{
          col: true,
          'q-pr-md': !_vm.$q.screen.lt.md,
          'col-12 q-mb-sm': _vm.$q.screen.lt.md
        }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.cta)?_c('a-secondary-btn',{attrs:{"label":_vm.cta,"to":_vm.to}}):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }