export default {
  wait_info:
    'De notaris is momenteel bezig met het ondertekenen van je akte en de inschrijving bij de KvK.',
  title: 'Gefeliciteerd!',
  title2: 'Gefeliciteerd',
  subtitle:
    'Nu is het tijd om een ​​betaalrekening voor jouw bedrijf te openen. Wij kunnen binnen 24 uur een gratis zakelijke betaalrekening openen bij GoDutch. Klik op de groene knop om jouw account aan te vragen.',
  rate: 'Beoordeel je ervaring',
  documents: 'Oprichtingsdocumenten van {name}',
  dashboard: 'Ga naar dashboard',
  finalized: 'Afgerond',
  expected: 'In afwachting',
  final_deed: 'Akte van oprichting',
  kvk: 'Uittreksel van de Kamer van Koophandel',
  document_folder: 'Documenten door jou geüpload',
  upload_document: '{total} documenten geüpload',
  steps: {
    incorporate: 'Richt mijn BV op',
    open_bank: 'Open een zakelijke betaalrekening',
    obligations: 'Stort de waarde van de aandelen op jouw bankrekening'
  },
  todo: "Gratis zakelijke rekening aanvragen",
  pending: 'Deze taak is nog niet gedaan'
};
