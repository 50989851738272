import { ApiService, notifyAsync, RootContext } from '@ligo/shared/utils';
import { RESOURCES } from '@ligo/dashboard/customer/store';
import { Ref, ref } from '@vue/composition-api';
import {
  NotaryProductDocument,
  NotaryProductDocumentKind
} from '@ligo/dashboard/store';
import { getDocumentsBy } from '../documents.hooks';
import { useStore } from '../../../composables';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useUploadDocuments(root: RootContext) {
  const dialog: Ref<NotaryProductDocument> = ref();
  const dialogLoading = ref(false);
  const form = ref();
  const file = ref();

  const store = useStore();
  const notaryProductId = store.getters['notaryProduct/getNotaryProductId'];

  const { items, loading, load } = getDocumentsBy(
    notaryProductId,
    {
      kinds: [
        NotaryProductDocumentKind.USER_UPLOADED,
        NotaryProductDocumentKind.NO_UPLOADED
      ]
    },
    true
  );

  function onUpload() {
    form.value.validate().then((valid) => {
      if (valid) {
        dialogLoading.value = true;

        const formData = new FormData();
        formData.append('file', file.value);

        notifyAsync(
          ApiService.patch(
            `${RESOURCES.NOTARY_PRODUCT_DOCUMENT(notaryProductId)}/${
              dialog.value.uuid
            }`,
            formData
          ).then(() => {
            file.value = null;
            dialog.value = null;
            dialogLoading.value = false;
            load();
          }),
          root.$t('documents.upload.success'),
          root.$t('documents.upload.error')
        );
      }
    });
  }

  return {
    items,
    loading,
    dialogLoading,
    form,
    file,
    dialog,
    onUpload,
    notaryProductId,
    load
  };
}
