




































































































































































import {
  defineComponent,
  ref,
  Ref,
  PropType,
  watch,
  computed
} from '@vue/composition-api';
import { useNotaryProductsTable, NPKinds } from './notary-product-table.hook';
import { SQTable, SQMainHeader, StepsBanner } from '../components/base';
import { EmptyListHandler } from '@ligo/dashboard/customer/store';
import { ActionTableHeader } from '../models/global/action-table';
import { useI18n } from '../composables';
import { SQTableRow } from '../components/base/tables/s-q-table.hook';
import APrimaryBtn from './base/app/buttons/APrimaryBtn.vue';
import AlertModal from './base/modals/AlertModal.vue';
import {
  IncorporationsPageInformation,
  ModificationsPageInformation
} from './utils/useCustomerPageInformation.hooks';
import { AppLink } from '@ligo/shared/components';
import ASecondaryBtn from './base/app/buttons/ASecondaryBtn.vue';
import { CtaBanner } from './base/banners';

interface UspCard {
  title: string;
  subtitle: string;
  icon: string;
}

interface HeaderCtaMenu {
  text: string;
  icon: string;
  link: string;
  post?: boolean;
}

const mobileHeight = '500px';
const height = '750px';

const emptyListHandler: EmptyListHandler = {
  title: 'product.empty_banner.title',
  subtitle: 'product.empty_banner.subtitle',
  btn: 'product.empty_banner.btn'
};

export default defineComponent({
  name: 'NotaryProductList',
  components: {
    SQTable,
    SQMainHeader,
    APrimaryBtn,
    ASecondaryBtn,
    AlertModal,
    AppLink,
    StepsBanner,
    CtaBanner
  },
  props: {
    externalLoading: {
      type: Boolean,
      default: false
    },
    kind: {
      type: String as PropType<NPKinds>,
      default: NPKinds.INCORPORATION
    }
  },
  setup(props, { root, emit }) {
    const i18n = useI18n();

    const {
      actionItems,
      loading,
      columns,
      pagination,
      filters,
      actions,
      itemsTotal,
      changeNotaryProductKind,
      search,
      onGoToDetails,
      onGoToQuestionnaire,
      deleteProduct,
      onGoToPay
    } = useNotaryProductsTable(root, emit, props.kind);

    const pageInformation = computed(() => {
      return props.kind === NPKinds.INCORPORATION
        ? IncorporationsPageInformation
        : ModificationsPageInformation;
    });

    pageInformation.value.load();

    const uspCards = computed(() => {
      if (pageInformation.value.isReady())
        return pageInformation.value.t('usp_cards') as UspCard[];
      return [];
    });

    const headerCtaMenu = computed<HeaderCtaMenu[]>(() => {
      if (
        pageInformation.value.isReady() &&
        props.kind !== NPKinds.INCORPORATION
      )
        return pageInformation.value.t('header_cta.menu');
      return [] as HeaderCtaMenu[];
    });

    const headers: ActionTableHeader[] = [
      {
        title: i18n.t('home.name'),
        width: '30%'
      },
      {
        title: i18n.t('home.service_type'),
        width: '20%'
      },
      {
        title: i18n.t('home.status'),
        width: '20%'
      },
      {
        title: i18n.t('home.last_viewed'),
        width: '20%'
      },
      {
        title: '',
        width: '10%'
      }
    ];

    const menuEvents = {
      delete: (id) => onSelectToDelete(id)
    };

    const showDeleteConfirmation = ref(false);
    const selectedProduct: Ref<string> = ref();

    function onSelectToDelete(id: string) {
      selectedProduct.value = id;
      showDeleteConfirmation.value = true;
    }

    function onDelete() {
      deleteProduct(selectedProduct.value);
      showDeleteConfirmation.value = false;
    }

    function onRowClick(item: SQTableRow) {
      const id = item.id.toString();
      switch (item.clickEvent) {
        case 'details':
          onGoToDetails(id);
          break;
        case 'questionnaire':
          onGoToQuestionnaire(id);
          break;
        case 'pay':
          onGoToPay(id);
          break;
        default:
          break;
      }
    }

    watch(
      () => props.kind,
      (newKind) => {
        changeNotaryProductKind(newKind);
        pageInformation.value.load();
      }
    );

    return {
      itemsTotal,
      actionItems,
      showDeleteConfirmation,
      mobileHeight,
      height,
      emptyListHandler,
      headers,
      menuEvents,
      loading,
      columns,
      pagination,
      filters,
      actions,
      NPKinds,
      uspCards,
      pageInformation,
      headerCtaMenu,
      search,
      onSelectToDelete,
      onDelete,
      onRowClick
    };
  }
});
