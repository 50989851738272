











































































import {
  defineComponent,
  PropType,
  ref,
  watch,
  onMounted,
  onUnmounted
} from '@vue/composition-api';
import BaseChatMessageComponent from './BaseChatMessage.vue';
import BaseChatScroll from './BaseChatScroll.vue';
import { ASecondaryBtn } from '../app/buttons';
import { WYSIWYGEditor } from '@ligo/shared/components';
import { BaseChatMessage } from '@ligo/dashboard/customer/store';

export default defineComponent({
  name: 'BaseChat',
  components: {
    BaseChatMessageComponent,
    BaseChatScroll,
    WYSIWYGEditor,
    ASecondaryBtn
  },
  props: {
    messages: {
      type: Array as PropType<BaseChatMessage[]>,
      required: true
    },
    sendingMessage: {
      type: Boolean,
      default: false
    },
    getMessageColor: {
      type: Function as PropType<(message: BaseChatMessage) => string>,
      default: () => () => {
        return 'np-green-100';
      }
    },
    isUserMessage: {
      type: Function as PropType<(message: BaseChatMessage) => boolean>,
      default: () => () => {
        return false;
      }
    },
    checkColor: {
      type: String,
      default: 'white'
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const newMessage = ref('');
    const scrollAreaRef = ref();

    const firstMessageUnread = ref(
      props.messages.find(
        (message) => !props.isUserMessage(message) && !message.seen_at
      )?.uuid
    );

    const unreadDelimiterId = ref(
      firstMessageUnread.value
        ? `unread-separator-${firstMessageUnread.value}`
        : undefined
    );

    function onSendMessage() {
      if (newMessage.value) {
        emit('send-message', newMessage.value);
      }
    }

    function setupInterceptionObserver() {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const message = props.messages.find(
                (message) => message.uuid === entry.target.id
              );
              if (message) {
                emit('on-message-visible', message);
              }
            }
          });
        },
        {
          root: scrollAreaRef.value.$el,
          threshold: 1
        }
      );

      watch(
        () => props.messages,
        (newMessages) => {
          for (const message of newMessages) {
            const element = document.getElementById(message.uuid);
            if (element) {
              observer.observe(element);
            }
          }
        },
        { immediate: true }
      );

      onUnmounted(() => {
        observer.disconnect();
      });
    }

    watch(
      () => props.sendingMessage,
      (loading) => {
        if (!loading) {
          newMessage.value = '';
        }
      }
    );

    onMounted(() => {
      setupInterceptionObserver();
    });

    return {
      newMessage,
      firstMessageUnread,
      unreadDelimiterId,
      scrollAreaRef,
      onSendMessage
    };
  }
});
