










import { defineComponent, watch } from '@vue/composition-api';
import PrepareDocument from '../../../components/documents/signing-document/PrepareDocument.handler.vue';
import { simpleNotification, NotificationType } from '@ligo/shared/utils';
import { useI18n } from '../../../composables';

export default defineComponent({
  name: 'DocumentPreparation',
  components: { PrepareDocument },
  props: {
    uuid: {
      type: String,
      required: true
    },
    assistant: {
      type: Boolean,
      default: false
    }
  },
  setup(_, { root }) {
    let clearNotification: () => void;
    const i18n = useI18n();
    watch(
      () => root.$q.screen.lt.sm,
      (isMobile) => {
        if (isMobile) {
          clearNotification = simpleNotification(
            i18n.t('e_signature.not_ready_feature.mobile'),
            NotificationType.warning
          ) as () => void;
        } else {
          clearNotification();
        }
      },
      { immediate: true }
    );

    return {};
  }
});
