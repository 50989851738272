export default {
  wait_info: 'The notary is preparing the Final Deed of Incorporation.',
  title: 'Congratulations!',
  title2: 'Congratulations',
  subtitle:
    'Now it’s time to open a payment account for your business. We can open a free business payment account with GoDutch within 24hs. Click on the green button to request your account today.',
  rate: 'Please rate your experience',
  documents: 'Incorporation documents of {name}',
  dashboard: 'Go to dashboard',
  finalized: 'Finalized',
  expected: 'Pending',
  final_deed: 'Deed of Incorporation',
  kvk: 'Extract of the chamber of commerce',
  document_folder: 'Documents you uploaded',
  upload_document: '{total} documents uploaded',
  steps: {
    incorporate: 'Incorporate my BV',
    open_bank: 'Open a business payment account',
    obligations: 'Deposit de value of the shares in your bank account',
  },
  todo: 'Request my free business account',
  pending: 'This task is pending'
};
