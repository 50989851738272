var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-card',{staticClass:"q-py-lg q-px-xl",attrs:{"flat":""}},[_c('q-card-section',{staticClass:"q-pa-none"},[_c('base-chat-scroll',{ref:"scrollAreaRef",attrs:{"initial-scroll":_vm.unreadDelimiterId},on:{"scroll-area-loaded":function($event){return _vm.$emit('on-chat-loaded')}}},_vm._l((_vm.messages),function(message){return _c('div',{key:("message-" + (message.uuid)),class:("q-mb-md full-width col-12 row justify-" + (_vm.isUserMessage(message) ? 'end' : 'start'))},[(message.uuid === _vm.firstMessageUnread)?_c('div',{staticClass:"row justify-center items-center col-12 q-mb-md",attrs:{"id":_vm.unreadDelimiterId}},[_c('q-separator',{staticClass:"col"}),_c('div',{staticClass:"q-px-sm"},[_vm._v(" "+_vm._s(_vm.$t('chat.unread_delimiter'))+" ")]),_c('q-separator',{staticClass:"col"})],1):_vm._e(),_c('base-chat-message-component',{staticClass:"col-11 col-sm-8 col-md-5",attrs:{"id":("" + (message.uuid)),"color":_vm.getMessageColor(message),"sender":message.author.name,"time":message.created_at,"content":message.content,"is-user-message":_vm.isUserMessage(message)},scopedSlots:_vm._u([{key:"checkmark",fn:function(){return [(_vm.isUserMessage(message))?_c('q-icon',{staticClass:"q-ml-sm",attrs:{"color":_vm.checkColor,"size":"14px","name":("fas fa-check" + (message.seen_at ? '-double' : ''))}}):_vm._e()]},proxy:true}],null,true)})],1)}),0)],1),(!_vm.readOnly)?_c('div',[_c('WYSIWYGEditor',{attrs:{"border":false,"bordered-content":true,"options":[
        'bold',
        'italic',
        'alignLeft',
        'alignCenter',
        'alignRight',
        'quote',
        'emoticons',
        'insertLink',
        'formatOL',
        'formatUL'
      ]},model:{value:(_vm.newMessage),callback:function ($$v) {_vm.newMessage=$$v},expression:"newMessage"}}),_c('q-card-actions',{staticClass:"q-px-none"},[_c('a-secondary-btn',{attrs:{"label":_vm.$t('chat.send').toString()},on:{"click":_vm.onSendMessage}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }