import { ref } from '@vue/composition-api';
import { ApiService } from '@ligo/shared/utils';
import { RESOURCES } from '@ligo/dashboard/customer/store';
import { NotaryProductDocumentKind } from '@ligo/dashboard/store';
import { NotaryProductService } from '@ligo/dashboard/customer/store';
import { getDocumentsBy, onDownload } from '../documents.hooks';
import { useStore } from '../../../composables';

const LOADER_TIMER = 2000;

export function useReadDocuments() {
  const store = useStore();

  const docsLoaded = ref(false);

  const notaryProductId = store.getters['notaryProduct/getNotaryProductId'];

  const { items, loading } = getDocumentsBy(
    notaryProductId,
    {
      kinds: [
        NotaryProductDocumentKind.GENERATED,
        NotaryProductDocumentKind.FIXED,
        NotaryProductDocumentKind.LIGO_UPLOADED
      ]
    },
    true
  );

  function loadNotaryProduct() {
    let interval = null;
    NotaryProductService.getById(notaryProductId).then(async (response) => {
      if (!response.data.waiting_for_documents) {
        docsLoaded.value = true;
        clearInterval(interval);
      } else if (!interval) {
        interval = setInterval(() => {
          loadNotaryProduct();
        }, LOADER_TIMER);
      }
    });
  }

  function onToggleRead(doc: any, index: number) {
    const status = !doc.status;
    items.value[index]['status'] = status;

    ApiService.patch(
      `${RESOURCES.NOTARY_PRODUCT_DOCUMENT(notaryProductId)}/${doc.uuid}`,
      { status }
    );
  }

  loadNotaryProduct();

  return {
    items,
    loading,
    docsLoaded,
    onToggleRead,
    onDownload
  };
}
