
































import { defineComponent, PropType } from '@vue/composition-api';
import { WYSIWYGEditor } from '@ligo/shared/components';
import LawyerCardComponent from './LawyerCard.vue';
import { LawyerCard } from '@ligo/dashboard/customer/store';

export default defineComponent({
  name: 'LawyerProfile',
  components: { WYSIWYGEditor, LawyerCardComponent },
  props: {
    description: String,
    card: Object as PropType<LawyerCard>,
    image: String,
    badges: Array as PropType<{ img: string; label: string }[]>
  },
  setup() {
    return {};
  }
});
