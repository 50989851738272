



































































































































































import {
  defineComponent,
  onBeforeMount,
  onMounted,
  computed,
  ref,
  watch
} from '@vue/composition-api';
import {
  NotaryProductStage,
  NotaryProductTypeSlug,
  UpsellPackageNotaryProductSlug
} from '@ligo/dashboard/store';
import {
  PaymentObject,
  sendGTMPaymentEvent
} from '@ligo/dashboard/customer/store';
import { NotaryProductEvent } from '@ligo/dashboard/store';

import { useNotaryProduct } from '../../components/notary-product-detail.hook';
import { useBuildPaymentDialog } from '../../components/payment-response.hook';
import { useNotaryProductChat } from '../../components/notary-product/chat/notary-product-chat.hook';

import ReadUploadStep from '../../components/notary-product/read-and-upload/ReadUploadStep.handler.vue';
import DeedDocumentHandler from '../../components/notary-product/draft-deed/DeedDocument.handler.vue';
import StateStepper from '../../components/notary-product/StateStepper.vue';
import FinalizedProduct from '../../components/notary-product/final-step/FinalizedProduct.vue';
import CheckDocuments from '../../components/notary-product/check-documents/CheckDocuments.vue';
import ScheduleMeeting from '../../components/notary-product/schedule-meeting/ScheduleMeeting.vue';
import MenuList from '../../components/notary-product/check-documents/Menu/MenuList.vue';
import ExtraQuestionsHandler from '../../components/notary-product/extra-questions/ExtraQuestions.handler.vue';
import BasePage from '../../components/base/BasePage.vue';
import AlertModal from '../../components/documents/base/AlertModal.vue';
import NotaryProductTabs from './NotaryProductTabs.vue';
import BaseChat from '../../components/base/chat/BaseChat.vue';
import { APrimaryBtn, ABtn } from '../../components/base/app/buttons';
import { VidyardVideoModal } from '@ligo/shared/components';

import { useStore, useI18n } from '../../composables';
import vidyardEmbed from '@vidyard/embed-code';

const ONBOARDING_VIDEO_UUID = 'E5wpQHM64JAA9RqkVgqYTG';

export default defineComponent({
  name: 'NotaryProductDetails',
  components: {
    DeedDocumentHandler,
    StateStepper,
    FinalizedProduct,
    CheckDocuments,
    ScheduleMeeting,
    ReadUploadStep,
    BasePage,
    ExtraQuestionsHandler,
    AlertModal,
    APrimaryBtn,
    ABtn,
    VidyardVideoModal,
    MenuList,
    NotaryProductTabs,
    BaseChat
  },
  props: {
    uuid: {
      type: String,
      required: true
    },
    dialog: {
      type: String
    },
    pUuid: {
      type: String
    }
  },
  setup(props, { root }) {
    const i18n = useI18n();

    const introductionVideoProducts = [
      NotaryProductTypeSlug.ANBI_STITCHING,
      NotaryProductTypeSlug.DUTCH_BV,
      NotaryProductTypeSlug.HOLDING,
      NotaryProductTypeSlug.OMZETTING,
      NotaryProductTypeSlug.STITCHING
    ];

    const editProductTypes = [
      NotaryProductTypeSlug.DUTCH_BV,
      NotaryProductTypeSlug.HOLDING,
      NotaryProductTypeSlug.OMZETTING,
      NotaryProductTypeSlug.ENGLISH_BV,
      NotaryProductTypeSlug.ENGLISH_HOLDING,
      UpsellPackageNotaryProductSlug.UPSELL_HOLDING,
      UpsellPackageNotaryProductSlug.UPSELL_INCORPORATE_HOLDING
    ];

    const editProductStages = [
      NotaryProductStage.CHECKING_DOCUMENTS,
      NotaryProductStage.LS_CHECKING_DOCUMENTS,
      NotaryProductStage.SUBMITTED
    ];

    const store = useStore();
    const showExplanationModal = ref(false);
    const continueLoading = ref(true);

    const rename = ref<string>('');

    const uuid = computed(() => props.uuid);

    onBeforeMount(async () => {
      await store.dispatch('notaryProduct/select', props.uuid);
      await store.dispatch('layout/cleanDashboardTitle');
    });

    const {
      status,
      loading,
      stage,
      stageNumber,
      productType,
      notary,
      notaryId,
      name,
      productData,
      vidyardTutorialId,
      load,
      onNotaryEvent
    } = useNotaryProduct(root, uuid);

    const {
      loadingChat,
      chatMessages,
      sendingMessage,
      unreadMessages,
      onMessageVisible,
      loadChat,
      onSendMessage
    } = useNotaryProductChat(props.uuid);

    // Custom stages for the notary product
    const extraQuestions = computed(
      () => stage.value == NotaryProductStage.EXTRA_QUESTIONS
    );
    const readUploadStep = computed(
      () => stage.value == NotaryProductStage.SUBMITTED
    );
    const checkDocuments = computed(
      () =>
        stage.value == NotaryProductStage.CHECKING_DOCUMENTS ||
        stage.value == NotaryProductStage.LS_CHECKING_DOCUMENTS
    );
    const deedDocumentHandler = computed(
      () => stage.value == NotaryProductStage.UPLOADING_FCA
    );
    const scheduleMeeting = computed(
      () => stage.value == NotaryProductStage.SCHEDULING_MEETING
    );
    const finalizedProduct = computed(() =>
      [
        NotaryProductStage.EXTRACTING_KVK,
        NotaryProductStage.EXECUTING_DEED,
        NotaryProductStage.FINALIZED
      ].includes(stage.value)
    );
    const readOnlyChatStage = computed(
      () => stage.value == NotaryProductStage.FINALIZED
    );

    const tabs = computed(() => [
      {
        label: i18n.t('page.notary_products.tabs.documents').toString(),
        key: 'documents'
      },
      {
        label: i18n.t('page.notary_products.tabs.chat').toString(),
        badge: computed(() => unreadMessages.value?.length).value,
        hide:
          !notary.value?.allow_chat ||
          (readOnlyChatStage.value && !chatMessages.value?.length),
        key: 'chat'
      }
    ]);
    const currentTab = ref(tabs.value[0].key);

    const showIntroductionVideoBtn = computed(() =>
      introductionVideoProducts.includes(productType.value)
    );

    if (props.pUuid) sendGTMPaymentEvent(props.pUuid);

    const customTitle = computed(() => {
      if (productType.value == NotaryProductTypeSlug.TRANSFER)
        return 'payment_messages.notary_product.title_transfer_np';
      else if (productType.value == NotaryProductTypeSlug.STATUTEN)
        return 'payment_messages.notary_product.title_statuten_np';
      return null;
    });

    const { paymentDialog, showDialog } = useBuildPaymentDialog(
      root,
      props.dialog,
      name,
      PaymentObject.NOTARY_PRODUCT,
      true,
      customTitle
    );

    function onCloseDialog() {
      load();
    }

    function stopLoading() {
      continueLoading.value = false;
    }

    function renderPlayer() {
      vidyardEmbed.api.renderPlayer({
        uuid: vidyardTutorialId.value || ONBOARDING_VIDEO_UUID,
        container: document.getElementById('onboarding-video')
      });
    }

    function onRename(name: string) {
      rename.value = name;
    }

    function showMenuButton() {
      return (
        editProductStages.includes(stage.value) &&
        editProductTypes.includes(productType.value)
      );
    }

    function onEvent(event: NotaryProductEvent) {
      continueLoading.value = true;
      onNotaryEvent(event, false);
    }

    onMounted(() => {
      loadChat();
      watch(unreadMessages, (newVal, oldValue) => {
        if (
          !oldValue?.length &&
          newVal?.length &&
          currentTab.value !== 'chat'
        ) {
          loadingChat.value = true;
          root.$nextTick(() => {
            loadingChat.value = false;
          });
        }
      });
    });

    return {
      status,
      loading,
      stage,
      productType,
      NotaryProductStage,
      stageNumber,
      notary,
      notaryId,
      paymentDialog,
      showDialog,
      productData,
      name,
      continueLoading,
      showExplanationModal,
      showIntroductionVideoBtn,
      ONBOARDING_VIDEO_UUID,
      vidyardTutorialId,
      rename,
      tabs,
      currentTab,
      extraQuestions,
      readUploadStep,
      checkDocuments,
      deedDocumentHandler,
      scheduleMeeting,
      finalizedProduct,
      readOnlyChatStage,
      loadingChat,
      chatMessages,
      sendingMessage,
      onSendMessage,
      onMessageVisible,
      onNotaryEvent,
      load,
      onCloseDialog,
      stopLoading,
      renderPlayer,
      onRename,
      showMenuButton,
      onEvent
    };
  }
});
