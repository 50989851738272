






import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted
} from '@vue/composition-api';

export default defineComponent({
  name: 'BaseChatScroll',
  props: {
    initialScroll: String
  },
  setup(props, { emit }) {
    const SCROLL_THRESHOLD = 2;

    const scrollAreaRef = ref();
    const observersToDisconnect = [];
    const previousScrollBottom = ref();

    function getScrollAreaHeight() {
      return (
        scrollAreaRef.value.scrollHeight - scrollAreaRef.value.clientHeight
      );
    }

    function scrollToBottom() {
      const scrollHeight = getScrollAreaHeight();
      scrollAreaRef.value.scrollTop = scrollHeight;
    }

    function scrollToElement(id: string) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }

    function waitScrollAreaIsFullyLoaded() {
      let timer = null;
      let resizeObserver = null;
      let nodeObserver = null;

      return new Promise((resolve) => {
        function onScrollAreaChanges() {
          if (timer) {
            clearTimeout(timer);
          }
          timer = setTimeout(() => {
            if (scrollAreaRef.value.scrollHeight === 0) return;
            resizeObserver.disconnect();
            nodeObserver.disconnect();
            resolve(1);
          }, 100);
        }

        resizeObserver = new ResizeObserver(onScrollAreaChanges);
        resizeObserver.observe(scrollAreaRef.value);

        nodeObserver = new MutationObserver(onScrollAreaChanges);
        nodeObserver.observe(scrollAreaRef.value, {
          childList: true,
          subtree: true
        });
      });
    }

    function setScrollAreaMutationObserver() {
      const nodeObserver = new MutationObserver(() => {
        if (
          Math.abs(
            previousScrollBottom.value - scrollAreaRef.value.scrollTop
          ) <= SCROLL_THRESHOLD
        ) {
          scrollToBottom();
        }
        previousScrollBottom.value = getScrollAreaHeight();
      });
      nodeObserver.observe(scrollAreaRef.value, {
        childList: true,
        subtree: true
      });

      function disconnect() {
        nodeObserver.disconnect();
      }

      return disconnect;
    }

    onMounted(() => {
      waitScrollAreaIsFullyLoaded().then(() => {
        if (props.initialScroll) {
          scrollToElement(props.initialScroll);
        } else {
          scrollToBottom();
        }
        previousScrollBottom.value = getScrollAreaHeight();
        observersToDisconnect.push(setScrollAreaMutationObserver());
        emit('scroll-area-loaded');
      });
    });

    onUnmounted(() => {
      observersToDisconnect.forEach((disconnect) => disconnect());
    });

    return { scrollAreaRef };
  }
});
